import { BrowserRouter, Route, Routes } from "react-router-dom";
import QrcodePro from "./app/QrcodePro";
import { CalcIndex } from "./calc/CalcIndex";
import { CalcPrivacy } from "./calc/CalcPrivacy";
import Privacy from "./Privacy";
import { SiteIndex } from "./SiteIndex";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SiteIndex />} />
                <Route path="/calc" element={<CalcIndex />} />
                <Route path="/calc/privacy" element={<CalcPrivacy />} />
                <Route path="/app/qrcodepro" element={<QrcodePro />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
        </BrowserRouter>
    );
}