import { NativeBaseProvider } from 'native-base';
import Router from './src/screen/Router';

export default function App() {
  return (
    <NativeBaseProvider>
      <Router />
    </NativeBaseProvider>
  );
}
