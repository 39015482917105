import { StyleSheet } from "react-native";

export const Color = {
    background: "#eee",
    text: "#555",
    link: "#35858B",
};

export const Styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.background,
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 30,
    },
    row: {
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        color: Color.text,
        fontWeight: "bold",
        fontSize: 50,
        margin: 16,
    },
    menuLink: {
        fontSize: 18,
        fontWeight: "bold",
        marginHorizontal: 4,
    },
    link: {
        fontSize: 16,
        color: Color.link,
    },
    text: {
        fontSize: 16,
        lineHeight: 22,
        color: Color.text,
    },
    linkSeparator: {
        fontSize: 16,
        lineHeight: 22,
        color: Color.text,
        marginHorizontal: 4,
    },
    body: {
        width: "50%",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    }
});


