import { A } from "@expo/html-elements";
import { Factory, HStack, Image, VStack, View } from "native-base";
import { AndroidStoreLink, AppleStoreLink } from "./StoreLink";

const NBA = Factory(A);

export function Card({ name, imageSource, href, androidId, iosId }) {
    return (
        <VStack flex={1} rounded={12} shadow={4} overflow="hidden">
            <Image 
                style={{ aspectRatio: 1024/500 }} 
                w="100%"
                resizeMode="cover" 
                source={imageSource}
                alt={name} />
            {/* ImageをAで囲うと若干paddingが入るため上に重ねる */}
            {/* <NBA href={href}
                w="100%" h="100%" 
                position="absolute" /> */}

            <HStack w="100%" minH="80px" space={2} alignItems="center" justifyContent="center" bg="white">
                <AppleStoreLink scale={0.7} id={iosId} />
                <AndroidStoreLink scale={0.7} id={androidId} />
            </HStack>
        </VStack>
    )
}
export function EmptyCard() {
    return (
        <VStack flex={1}>
            <View style={{ aspectRatio: 1024 / 500 }} w='100%' />
            <View w="100%" minH="80px" space={2} />
        </VStack>
    )
}
