import React from "react";
import { Text } from "react-native";
import { A } from '@expo/html-elements';
import { Link } from "react-router-dom";
import { Styles } from "../conf/Styles";

export const TextLink = ({ style, href = '', children }) => {
    const isRelative = href.startsWith('/');
    return (
        <>{isRelative ?
            <Link to={href} style={{ textDecoration: 'none' }}>
                <Text style={[Styles.link, style]}>{children}</Text>
            </Link>
            :
            <A href={href}>{ children }</A>
        }</>
    );
};
