import React from "react";
import { ImageBackground } from "react-native";
import { Ionicons } from '@expo/vector-icons';
import { Box, Center, Divider, Heading, HStack, View, Text, useBreakpointValue, Stack, Flex, Spacer } from "native-base";
import { A } from "@expo/html-elements";
import { Card, EmptyCard } from "../component/Card";

export const SiteIndex = () => {
    const flexDir = useBreakpointValue({
        base: 'column', md: 'row'
    });

    return (
        <Box>
            <Box h="300px">
                <ImageBackground style={{ width: "100%", height: "100%" }} source={require('../../assets/header_background.jpg')}>
                    <Center h="100%" bg="#00000077">
                        <Heading size="2xl" color="white">tadaedo.com</Heading>
                        <Divider w="50%" />
                        <HStack space={2} mt={4} alignItems="center">
                            <A href="https://twitter.com/tadaedo">
                                <Ionicons name="logo-twitter" size={24} color="#1DA1F2" />
                            </A>
                            <Divider orientation="vertical" h={6} />
                            <A href="https://zenn.dev/tadaedo">
                                <Text size="lg" color="white" bold>Zenn</Text>
                            </A>
                            {/* <Divider orientation="vertical" h={6} />
                            <A href="mailto:tadaedo+site@gmail.com">
                                <Ionicons name="mail" size={24} color="white" />
                            </A> */}
                        </HStack>
                    </Center>
                </ImageBackground>
            </Box>
            <Box flex={1} w="100%" p={8} bg="#fafafa" px={flexDir == "row" ? "10%" : 8}>
                <Box pb={4}>
                    <Heading size="md" color="#999">Application</Heading>
                </Box>

                <Stack direction={flexDir} w="100%" space={16} mb={16} justifyContent="center">
                    <Card name="Infinite Egg"
                        href="/"
                        imageSource={require('../../assets/app/egg/feature.png')}
                        androidId='com.tadaedo.app.egg' iosId='6479361937' />
                    <Card name="DigiClock"
                        href="/"
                        imageSource={require('../../assets/app/clock/feature.png')}
                        androidId='com.tadaedo.app.clock' iosId='6476612907' />
                </Stack>

                <Stack direction={flexDir} w="100%" space={16} mb={16} justifyContent="center">
                    <Card name="Simple Calculator"
                        href="/calc"
                        imageSource={require('../../assets/app/calc/feature.png')}
                        androidId='org.tadaedo.calc' iosId='1605441213' />
                    <Card name="QRCode PRO"
                        href="/app/qrcodepro"
                        imageSource={require('../../assets/app/qrcode/feature.png')}
                        androidId='org.tadaedo.app.qrcodepro' iosId='6443524810' />
                </Stack>

                <View h="200" />
                <Divider />
                <HStack my={3} justifyContent="center">
                    <A href="/privacy">
                        <Text size="lg" color="#999">Privacy Policy</Text>
                    </A>
                </HStack>
            </Box>
        </Box>
    );
};
