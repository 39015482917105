import React from "react";
import { Image, Text, View } from "react-native";
import { Styles } from "../conf/Styles";

import { TextLink } from "../component/TextLink";

export default function Privacy() {

    return (
        <View style={Styles.container}>
            <Text style={Styles.title}>
                Privacy Policy
            </Text>

            <View style={[Styles.body, { marginTop: 30 }]}>
                <Text style={Styles.text}>
                    The copyrights and portrait rights of the images posted on this service belong to the respective rights holders. We do not intend to infringe on their rights. If there are any problems with the content of the article or the images posted, please contact us directly by e-mail. We will respond to you after confirmation.{"\n"}
                    {"\n"}
                    In the event that you are transferred from this site to another site via a link or banner, we assume no responsibility whatsoever for the information, services, etc. provided by the destination site.{"\n"}
                    {"\n"}
                    While we strive to ensure that the content and information on this site is as accurate as possible, there may be times when erroneous information is introduced or the information is out of date.{"\n"}
                    {"\n"}
                    Please note that we cannot be held responsible for any damages caused by the contents of this website.{"\n"}
                </Text>
            </View>

            <View style={[Styles.row, { marginTop: 30 }]}>
                <TextLink style={Styles.menuLink} href="/">
                    HOME
                </TextLink>
            </View>
        </View>
    );
};
