import React from "react";
import { Image } from "react-native";
import { A } from "@expo/html-elements";

export const AndroidStoreLink = ({ id = '', scale = 1, ...props }) => {
    const href = 'https://play.google.com/store/apps/details?id=' + id;
    return (
        <>{ id &&
            <A href={href} target="_blank" {...props}>
                <Image
                    style={{
                        width: 646 / 3.2 * scale,
                        height: 250 / 3.2 * scale,
                    }}
                    source={require('../../assets/store/google-play-badge.png')}/>
            </A>
        }</>
    );
};

export const AppleStoreLink = ({ id = '', scale = 1, ...props }) => {
    const href = `https://itunes.apple.com/jp/app/${id}?mt=8`
    return (
        <>{id &&
            <A href={href} target="_blank" {...props}>
                <Image
                    style={{
                        width: 160 * scale,
                        height: 54 * scale,
                    }}
                    source={require('../../assets/store/apple-store.png')} />
            </A>
        }</>
    );
};
