import React from "react";
import { Image, Text, View } from "react-native";
import { Styles } from "../../conf/Styles";
import { AndroidStoreLink, AppleStoreLink } from "../../component/StoreLink";
import { TextLink } from "../../component/TextLink";

export const CalcIndex = () => {

    return (
        <View style={Styles.container}>
            <Image source={require('../../../assets/calc-icon.png')} style={{ width: 100, height: 100}} />
            <Text style={Styles.title}>
                Simple Calculator
            </Text>

            <View style={[Styles.row, { marginLeft: 35 }]}>
                <AppleStoreLink id="1605441213" />
                <AndroidStoreLink id="org.tadaedo.calc" />
            </View>

            <View style={[Styles.row, { marginTop: 30 }]}>
                <TextLink style={Styles.menuLink} href="/">
                    HOME
                </TextLink>
                <Text style={Styles.linkSeparator}>/</Text>
                <TextLink style={Styles.menuLink} href="/calc/privacy">
                    Privacy Policy
                </TextLink>
            </View>
        </View>
    );
};
