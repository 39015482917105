import { A } from '@expo/html-elements';
import { ArrowBackIcon, AspectRatio, Box, Center, Divider, HStack, Image, ScrollView, Spacer, Stack, Text, useBreakpointValue, View, VStack } from 'native-base';
import { Image as RNImage } from 'react-native';

export default function QrcodePro() {
    const flexDir = useBreakpointValue({
        base: 'column', sm: 'row'
    });

    return (
        <Box>
            <Center>
                <RNImage style={{ width: "100%", maxWidth: 700, aspectRatio: 1024/500 }}
                    source={require('../../../assets/app/qrcode/feature.png')} />
            </Center>
            <VStack space={8}>
                <Stack direction={flexDir} w="100%" space={4} alignItems="center" justifyContent="center">
                    <AspectRatio w="30%" maxW="300" ratio={1080 / 2220}>
                        <Image source={require('../../../assets/app/qrcode/screen1.png')} />
                    </AspectRatio>
                    <Box>
                        Quick Scanning.
                    </Box>
                </Stack>
                <Stack direction={flexDir} w="100%" space={4} alignItems="center" justifyContent="center">
                    <AspectRatio w="30%" maxW="300" ratio={1080 / 2220}>
                        <Image source={require('../../../assets/app/qrcode/screen4.png')} />
                    </AspectRatio>
                    <Box>
                        Quick Scanning.
                    </Box>
                </Stack>
            </VStack>

            <HStack w="100%" h="300" px={8} space={8} justifyContent="center" bg="green.100" overflow="scroll">
                <AspectRatio ratio={1080 / 2220}>
                    <Image source={require('../../../assets/app/qrcode/screen1.png')} />
                </AspectRatio>
                <AspectRatio ratio={1080 / 2220}>
                    <Image source={require('../../../assets/app/qrcode/screen2.png')} />
                </AspectRatio>
                <AspectRatio ratio={1080 / 2220}>
                    <Image source={require('../../../assets/app/qrcode/screen3.png')} />
                </AspectRatio>
                <AspectRatio ratio={1080 / 2220}>
                    <Image source={require('../../../assets/app/qrcode/screen4.png')} />
                </AspectRatio>
            </HStack>

            <View h="300" />
            <Divider />
            <HStack my={3} justifyContent="center">
                <A href="/privacy">
                    <Text size="lg" color="#999">Privacy Policy</Text>
                </A>
            </HStack>

            <Center rounded="full" size="50" overflow="hidden" bg="black" position="absolute" top={0} left={0} m={3}>
                <ArrowBackIcon color="white" />
            </Center>
        </Box>
    );
}